import React from 'react';
import { ErrorSVG } from 'assets/icons';
import './Notification.scoped.scss';

const Notification = ({ text, type, children }) => {
  return (
    <div className="notification">
      <ErrorSVG />
      {text}
    </div>
  );
};

export default Notification;
