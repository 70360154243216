import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'normalize.css';
import 'fonts.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import WebFont from 'webfontloader';
import cssVars from 'css-vars-ponyfill';

import { ContextWrapper as AuthContextWrapper } from 'contexts/AuthContext';
import { ContextWrapper as AppContextWrapper } from 'contexts/AppContext';
import * as serviceWorker from './serviceWorker';
import './vhfix';

WebFont.load({
  custom: {
    families: ['Inter:400,500,700']
  }
});

cssVars();

const app = (
  <AppContextWrapper>
    <AuthContextWrapper>
      <App />
    </AuthContextWrapper>
  </AppContextWrapper>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
