import React from 'react';
import './AuthContainer.scoped.scss';

const AuthContainer = ({ children }) => {
  return (
    <div className="auth-container">
      <div className="page-content scrollable">{children}</div>
    </div>
  );
};

export default AuthContainer;
