import { useEffect } from 'react';

function useOutsideClick(ref, callback) {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      if (!callback) return console.warn('useOutsideClick: No callback provided');
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
}

export default useOutsideClick;
