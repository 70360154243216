import React, { useCallback } from 'react';
import toaster from 'toasted-notes';

import Notification from 'components/Notification';

export default function useNotify() {
  return useCallback((text, props, settings) => {
    toaster.notify(notifyProps => <Notification text={text} {...props} {...notifyProps} />, {
      duration: 3000,
      position: 'bottom',
      ...settings
    });
  }, []);
}
