import React from 'react';
import './LoadingSpinner.scoped.scss';
import clsx from 'clsx';
import { LoaderSVG } from 'assets/icons';

const LoadingSpinner = ({ className, iconProps, ...rest }) => (
  <span className={clsx('loader', className)} {...rest}>
    <LoaderSVG />
  </span>
);

export default LoadingSpinner;
