import React, { useState, useEffect, useCallback, useContext } from 'react';
import Cookies from 'js-cookie';
import api from 'api';
import AppContext from './AppContext';

const AuthContext = React.createContext();

function checkCookie() {
  return Cookies.get('authData') !== undefined;
}

function formatAuthData(data) {
  return {
    accessToken: data.access_token.token,
    accessTokenExpire: Number(data.access_token.expiry),
    refreshToken: data.refresh_token.token,
    refreshTokenExpire: Number(data.refresh_token.expiry)
  };
}

export const ContextWrapper = ({ children }) => {
  const { resetAppContext } = useContext(AppContext);
  const [isAuthorized, setIsAuthorized] = useState(checkCookie());
  const [authData, setAuthData] = useState(null);

  function unauthorize() {
    Cookies.remove('authData');
    setIsAuthorized(false);
  }

  function logout() {
    unauthorize();
    resetAppContext();
    const body = { headers: { Authorization: authData && `Bearer ${authData.accessToken}` } };
    api.delete('tokens/revoke', body);
  }

  function authorize(data) {
    const authData = formatAuthData(data);
    Cookies.set('authData', JSON.stringify(authData));
    setAuthData(authData);
    setIsAuthorized(true);
  }

  async function refreshAccessToken() {
    const authDataCookie = Cookies.get('authData');
    const { refreshToken } = JSON.parse(authDataCookie);
    const body = { refresh_token: refreshToken };
    try {
      const { data } = await api.post('tokens/refresh', body);
      authorize(data);
    } catch (err) {
      logout();
    }
  }

  const refresh = useCallback(refreshAccessToken, []);

  useEffect(() => {
    const authDataCookie = Cookies.get('authData');
    if (!authDataCookie) {
      return unauthorize();
    }

    const { refreshTokenExpire } = JSON.parse(authDataCookie);
    const isExpired = Date.now() > refreshTokenExpire * 1000;
    if (isExpired) return logout();

    setIsAuthorized(true);
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const contextValues = {
    isAuthorized,
    setIsAuthorized,
    refreshAccessToken: refresh,
    authData,
    authorize,
    logout
  };

  return <AuthContext.Provider value={contextValues}>{children}</AuthContext.Provider>;
};

export default AuthContext;
