import api from './api';
import moment from 'moment';

export async function getProjects(config) {
  const { data } = await api.get('/property-details', config);
  if (!data.length) throw Error('No projects');
  const projects = data.map(item => ({
    id: item.id,
    name: item.prop_address || `Property ID-${item.id}`,
    open: item.prop_status === 'Open'
  }));
  return projects;
}

export async function getTeamMembers(projectId, config) {
  if (!projectId) throw Error('Missing projectId');
  const { data } = await api.get(`/property-details/${projectId}`, config);

  const formatPhoto = str => {
    if (!str) return null;
    if (str.includes('no_image.jpg')) return null;
    return str;
  };

  const formatRole = str => {
    if (!str) return null;
    if (str.includes('Attorney')) return 'Attorney';
    return str;
  };

  const users = data.map(({ user }) => {
    return {
      id: user.id,
      name: user.user_info.name,
      photo: formatPhoto(user.user_info.photo_loc),
      email: user.email,
      phone: user.user_info.phone,
      phoneOffice: user.user_info.phone_office,
      role: formatRole(user.participant.participant_role.role_name)
    };
  });
  return users;
}

export async function getDeadlines(projectId, config) {
  const { data } = await api.get(`/property-details/${projectId}/deadlines`, config);
  const deadlines = data
    .sort((a, b) => moment(a.deadline) - moment(b.deadline))
    .map(item => {
      const date = moment(item.deadline * 1000).format('MM/DD/YYYY');
      const today = moment().format('MM/DD/YYYY');
      const open = moment(date).isSameOrAfter(today);
      return { id: item.id, description: item.description, date, open };
    });
  return deadlines;
}
