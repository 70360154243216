import React, { useContext, Suspense } from 'react';
import { Router, Redirect } from '@reach/router';
import './App.scss';

import AuthContext from 'contexts/AuthContext';
import AppContainer from 'containers/AppContainer';
import AuthContainer from 'containers/AuthContainer';
import LoadingSpinner from 'components/LoadingSpinner';

const Projects = React.lazy(() => import('views/Projects'));
const Help = React.lazy(() => import('views/Help'));
const Login = React.lazy(() => import('views/Login'));
const Privacy = React.lazy(() => import('views/Privacy'));
const Terms = React.lazy(() => import('views/Terms'));
const Register = React.lazy(() => import('views/Register'));
const ForgotPassword = React.lazy(() => import('views/ForgotPassword'));
const ResetPassword = React.lazy(() => import('views/ResetPassword'));

const Route = ({ component: Component, path, allow = true, redirect = '/', debug, ...rest }) => {
  if (debug) console.debug(`Redirect from ${path} to ${redirect}`);
  if (!allow) return <Redirect from={path} to={redirect} noThrow />;
  return <Component path={path} {...rest} />;
};

const App = () => {
  const { isAuthorized } = useContext(AuthContext);
  const fallbackComponent = <LoadingSpinner className="loader-centered" />;
  const Container = isAuthorized ? AppContainer : AuthContainer;
  const noMatch = isAuthorized ? '/projects' : '/login';

  return (
    <div className="app">
      <Container>
        <Suspense fallback={fallbackComponent}>
          <Router id="router" className="page-content-wrapper">
            <Redirect from="*" to={noMatch} noThrow />
            <Route path="projects" component={Projects} allow={isAuthorized} />
            <Route path="help" component={Help} allow={isAuthorized} />
            <Route path="login" component={Login} allow={!isAuthorized} />
            <Route path="privacy" component={Privacy} allow={!isAuthorized} />
            <Route path="terms" component={Terms} allow={!isAuthorized} />
            <Route path="forgot-password" component={ForgotPassword} allow={!isAuthorized} />
            <Route path="reset-password/:token" component={ResetPassword} />
            <Route
              path="register/:token/:inviter_user_id"
              component={Register}
              allow={!isAuthorized}
            />
          </Router>
        </Suspense>
      </Container>
    </div>
  );
};

export default App;
