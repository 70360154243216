import { useState, useContext, useEffect } from 'react';
import axios from 'axios';

import { isCancel } from 'api';
import AuthContext from 'contexts/AuthContext';
import useNotify from 'hooks/useNotify';

export default function useRequest(request, params) {
  const notify = useNotify();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const { authData, refreshAccessToken } = useContext(AuthContext);

  useEffect(() => {
    if (!authData) return;

    const source = axios.CancelToken.source();
    const config = {
      cancelToken: source.token,
      headers: { Authorization: `Bearer ${authData.accessToken}` }
    };

    const requestWithAuth = async () => {
      try {
        const data = await (params ? request(params, config) : request(config));
        setData(data);
      } catch (err) {
        if (err.response && err.response.status === 401) refreshAccessToken();
        if (!isCancel(err)) notify(err.message || 'Unknown error');
      }
      setLoading(false);
    };

    requestWithAuth();

    return () => {
      source.cancel();
    };
  }, [request, params, authData, notify, refreshAccessToken]);

  return [data, loading];
}

export function useCreateRequest(request) {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const { authData, refreshAccessToken } = useContext(AuthContext);

  const source = axios.CancelToken.source();
  const config = { cancelToken: source.token };
  if (authData) config.headers = { Authorization: `Bearer ${authData.accessToken}` };

  const requestWithConfig = async params => {
    setLoading(true);
    try {
      const data = await request(params, config);
      return data;
    } catch (err) {
      if (err.response && err.response.status === 401) refreshAccessToken();
      notify(err.message);
    }
    setLoading(false);
  };

  const cancelRequest = source.cancel;

  return [requestWithConfig, loading, cancelRequest];
}
