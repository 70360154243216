import React, { useState, useRef, useContext } from 'react';
import './Dropdown.scoped.scss';
import clsx from 'clsx';
import { ChevronDownSVG } from 'assets/icons';
import useOutsideClick from 'hooks/useOutsideClick';
import Popper from 'components/Popper';
import AppContext from 'contexts/AppContext';

const dot = (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="dot">
    <rect width="7.5" height="7.5" rx="4" fill="#507BF7" />
  </svg>
);

const Dropdown = ({
  children,
  className,
  value,
  keyName = 'key',
  options = [],
  placeholder,
  block,
  onChange,
  ...rest
}) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const { isMobile } = useContext(AppContext);
  const toggleIsOpen = () => setOpen(open => !open);
  const selectedOption = options.find(
    item => Object(item).hasOwnProperty(keyName) && item[keyName] === value
  );

  function handleOptionClick(e, option) {
    e.stopPropagation();
    setOpen(false);
    if (onChange) onChange(option[keyName]);
  }

  function handleOutsideClick() {
    if (open) setOpen(false);
  }

  useOutsideClick(ref, handleOutsideClick);

  const rootElement = document.querySelector('#root');
  const popperContainer = isMobile ? rootElement : ref ? ref.current : rootElement;
  const selectable = options.length > 1;

  return (
    <div
      ref={ref}
      onClick={toggleIsOpen}
      className={clsx('dropdown', { selectable, open, block }, className)}
      {...rest}>
      <div className="value">
        {selectedOption ? selectedOption.headerText || selectedOption.text : placeholder}
      </div>
      {selectable && (
        <Popper open={open} container={popperContainer}>
          <ChevronDownSVG className="icon" />
          <ul className="dropdown-options scrollable">
            {options.map(option => {
              const active = option[keyName] === value;
              return (
                <li
                  role="button"
                  key={option[keyName]}
                  onClick={e => handleOptionClick(e, option)}
                  className={clsx('option', { active })}>
                  {active && dot} {option.text}
                </li>
              );
            })}
          </ul>
        </Popper>
      )}
    </div>
  );
};

export default Dropdown;
