import React from 'react';
import './AppContainer.scoped.scss';
import Header from './Header';

const AppContainer = ({ children }) => {
  return (
    <div className="app-container">
      <Header />
      <div className="page-content scrollable" id="scroll">
        {children}
      </div>
    </div>
  );
};

export default AppContainer;
