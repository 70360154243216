import { useEffect } from 'react';

export default function useScrollToTop(ref, target) {
  const targetElement = document.getElementById(target);

  const handleClick = () => {
    targetElement.scrollTo({
      behavior: 'smooth',
      top: 0
    });
  };

  useEffect(() => {
    if (ref && ref.current && targetElement) {
      const refElement = ref.current;
      refElement.addEventListener('click', handleClick);

      return () => {
        refElement.removeEventListener('click', handleClick);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, targetElement]);

  return null;
}
