import axios from 'axios';

const config = {
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true
};

const api = axios.create(config);

export const getSource = () => axios.CancelToken.source();

export const isCancel = thrown => axios.isCancel(thrown);

export const getFirstResponseError = errorObj => Object.values(errorObj.data.errors)[0];

export default api;
