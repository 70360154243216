import React, { useEffect, useMemo, useContext, useRef } from 'react';

import './Header.scoped.scss';
import { getProjects } from 'api/projects';
import useRequest from 'hooks/useRequest';
import useScrollToTop from 'hooks/useScrollToTop';
import { AppLogo } from 'assets/icons';
import Dropdown from 'components/Dropdown';
import AppContext from 'contexts/AppContext';
import AuthContext from 'contexts/AuthContext';
import LoadingSpinner from 'components/LoadingSpinner';
import clsx from 'clsx';

function getProjectsOptions(projects) {
  return projects.map(({ id, name, open }) => ({
    key: id,
    headerText: name,
    text: (
      <div className={clsx('option-text', { closed: !open })}>
        <span className="option-name">{name}</span>{' '}
        {open ? null : <span className="status closed">Closed</span>}
      </div>
    )
  }));
}

const Header = () => {
  const { selectedProject, setSelectedProject } = useContext(AppContext);
  const { logout } = useContext(AuthContext);
  const [projects = [], loading] = useRequest(getProjects);
  const ref = useRef(null);
  const projectsOptions = useMemo(() => getProjectsOptions(projects), [projects]);

  useScrollToTop(ref, 'scroll');

  useEffect(() => {
    if (!selectedProject && projects.length) {
      const { id } = projects[0];
      setSelectedProject(id);
    }
  }, [projects, selectedProject, setSelectedProject]);

  return (
    <header className="app-header">
      <AppLogo className="logo" ref={ref} data-cy="logo" />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Dropdown
          options={projectsOptions}
          value={selectedProject}
          onChange={setSelectedProject}
          data-cy="dropdown_projects"
        />
      )}
      <button onClick={logout} className="button-logout" data-cy="button_logout">
        Logout
      </button>
    </header>
  );
};

export default React.memo(Header);
