import React, { useState } from 'react';
import { useMedia } from 'react-media-hook';

const AppContext = React.createContext();

export const ContextWrapper = ({ children }) => {
  const [selectedProject, setSelectedProject] = useState();
  const [popupShown, setPopupShown] = useState();
  const mobileBreakpoint = '768px';
  const { matches: isMobile } = useMedia(`(max-width: ${mobileBreakpoint})`);

  function resetAppContext() {
    setSelectedProject();
    setPopupShown();
  }

  const contextValues = {
    selectedProject,
    setSelectedProject,
    popupShown,
    setPopupShown,
    isMobile,
    resetAppContext
  };

  return <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>;
};

export default AppContext;
