import React, { useRef } from 'react';
import './Popper.scoped.scss';
import PropTypes from 'prop-types';
import { Manager, Reference, Popper as PopperComponent } from 'react-popper';
import useOutsideClick from 'hooks/useOutsideClick';

const Popper = ({
  children,
  placement = 'bottom',
  container: containerProp,
  open,
  onOutsideClick
}) => {
  const content = children[1];
  const target = content ? children[0] : children;
  const container = containerProp || document.querySelector('#root');
  const contentRef = useRef(null);

  function handleOutsideClick() {
    if (onOutsideClick) onOutsideClick();
  }

  useOutsideClick(contentRef, handleOutsideClick);

  const modifiers = [
    { name: 'flip', options: { fallbackPlacements: ['left', 'right', 'bottom'] } },
    { name: 'preventOverflow', options: { padding: 8, boundary: container } },
    { name: 'offset', options: { offset: [0, 15] } }
  ];

  return (
    <Manager>
      <Reference>{({ ref }) => ({ ...target, ref })}</Reference>
      {open && (
        <PopperComponent placement={placement} modifiers={modifiers}>
          {({ ref, style, placement, arrowProps }) => (
            <div ref={ref} style={style} data-placement={placement} className="popper">
              <div ref={arrowProps.ref} style={arrowProps.style} className="arrow" />
              <div ref={contentRef} className="content">
                {content}
              </div>
            </div>
          )}
        </PopperComponent>
      )}
    </Manager>
  );
};

Popper.propTypes = {
  placement: PropTypes.string,
  open: PropTypes.bool,
  onOutsideClick: PropTypes.func
};

export default React.memo(Popper);
